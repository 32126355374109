import React, { useState, useEffect, useContext} from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Box from '@mui/material/Box';
import LargeCard from '../../components/LargeCard/LargeCard';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useStyles } from './styled';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import {Grid, Stack} from '@mui/material/';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import CachedIcon from '@mui/icons-material/Cached';
import ErrorIcon from '@mui/icons-material/Error';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import { StepIconProps } from '@mui/material/StepIcon';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import CircleIcon from '@mui/icons-material/Circle';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { User, updateVisitor} from '../../services/kioskApi';
import { PersonaContext } from '../../context/PersonaContext';
import { LanguageContext } from '../../context/LanguageContext';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/';

import ScenarioImage1 from '../../static/images/Scenario-1.png'
import ScenarioImage2 from '../../static/images/Scenario-2.png'
import ScenarioImage3 from '../../static/images/Scenario-3.png'
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface Props {}

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <div>
      {completed ? (
        <Check className='QontoStepIcon-completedIcon' />
      ) : active ? (
        <ArrowDownwardIcon sx={{ color: 'blue' }} />
      ) : (
        <div
          style={{
            width: 12,
            height: 12,
            borderRadius: '50%',
            background: 'white',
          }}
        />
      )}
    </div>
  );
}

const TimelinePage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const steps = [
    'Eligibility',
    'Naturalization Application N-400',
    'Receipt of Application',
    'Biometrics',
    'Naturalization Interview',
    'Decision',
  ];

  const steps2 = [
    'Eligibility',
    'File Immigration Petition',
    'Approval of Immigration Petition',
    'Register Permanent Residence',
    'USCIS Review',
    'Biometrics Appointment Scheduled',
    'Biometrics Collected',
    'USCIS Interview Scheduled', 
    'USCIS Interview Completed',
    'Green Card Issued'
  ];

  const [activeStep, setActiveStep] = React.useState(0);

  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  console.log(context.visitor_id);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  // let [timelineSteps, setTimelineSteps] = React.useState<string[]>(['a', 'b']);
  let [scenario, setScenario] = React.useState(0);
  let scenarioLabels = [ "", "Naturalization", "Greencard", "Benefits"];
  let scenarioImages = [ScenarioImage1, ScenarioImage2, ScenarioImage3];
  let navigate = useNavigate();

  // let randomStep: number = getRandomInt(6);

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
  }, []);

  function getRandomInt(max:number) {
    return Math.floor(Math.random() * max);
  }


  const [state, setState] = React.useState({
    scenarioGraphic: '0'
  
  })

  const handleNextStep = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (context.scenario === 1) {
      navigate(`/paynow`);
    } else if (context.scenario === 2) {
      navigate(`/biometricsNow`);
    } else if (context.scenario === 3) {
      navigate(`/rejectedStatus`);
    }
  };

  const image = () =>{
      if(context.scenario === 1){
        return <img src={ScenarioImage1}  style={{borderRadius: '25px', width: '100%', textAlign: 'center', margin: '20px auto'}} />
      }

      if(context.scenario === 2){
        return <img src={ScenarioImage2}  style={{borderRadius: '25px', width: '100%', textAlign: 'center', margin: '20px auto'}} />
      }

      if(context.scenario === 3){
        return <img src={ScenarioImage3}  style={{borderRadius: '25px', width: '100%', textAlign: 'center', margin: '20px auto'}} />
       }
  }
  return (
    <Header>
    <Grid container style={{marginTop: 100}}>
      <Grid item xs={1}></Grid>
      <Grid container item xs={10} justifyContent='center'>
          <Typography
            variant='h3'
            align='center'
            justifyContent='center'
            justifyItems='center'
            sx={{ fontWeight: 'bold', color: '#fff', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
          >
            {scenarioLabels[context.scenario].toUpperCase()} {t('currentstatus')}
          </Typography>
          {/* <LargeCard> */}
          <Stack spacing={4} style={{marginTop: 100}} justifyItems='center'>
              {image()}
              <Box>
                <Button
                  variant='contained'
                  onClick={(e : React.MouseEvent<HTMLButtonElement>) => handleNextStep(e)}
                  style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
                >
                  {t('continue')}
                </Button>
              </Box>
            </Stack>
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>
    </Header>
  );
};

export default TimelinePage;
