import React from 'react';
import Container from '@mui/material/Container';
import './App.css';
import { ApiProvider } from './context/PersonaContext';
import Router from './Router';
import { LanguageProvider } from './context/LanguageContext';
import Particles from "react-tsparticles";


const App = () => {
  const particlesInit = (main: any) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = (container : any) => {
    console.log(container);
  };
  return (
    <>
   
    <LanguageProvider>
      <ApiProvider>
      

          <Router />
 
      </ApiProvider>
    </LanguageProvider>
    </>
  );
};

export default App;

// localstorage - espa local to c's item
// top level tsx file called routes.tsx - main entry point

// AppContent is routespage

// Components - if it is repeatable make a component. have the handler in the component, and pass component -- to avoid nested calls

// services
// rtkquery and rtkmutations or axios
// if using axios - define an api and have endpoints connected - by passing arguments and stuff
