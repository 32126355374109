import React, { Component } from 'react';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from "styled-components";
import { useContext, useEffect } from 'react';

interface Props {}

const ChatBotServiceComponent: React.FC<Props> = (props) => {
  let answer = 2;
  useEffect(() => {

    answer = 5;
      
  }, [])

  return (
    <>
    <div>The answer is {answer}</div>
    </>
  ) 
}


const ChatBotComponent: React.FC<Props> = (props) => {

  const theme = {
    background: "#fff",
    headerBgColor: "#c4d34f",
    headerFontColor: "#3c6cb2",
    userBubbleColor: "#d0def2",
    fontFamily: 'Helvetica Neue',
    headerFontSize: '16px',
  };

  const steps = [
    {
      id: '0',
      message: 'Welcome to react chatbot!',
      trigger: '1',
    },
    {
      id: '1',
      message: 'Please ask a question',
      trigger: '2',
    }, 
    {
      id: '2',
      user: true,
      message: 'We will have an answer to that question shortly',
      trigger: '3',
    },
    {
      id: '3',
      component: <ChatBotServiceComponent/>,
      waitAction: true,
      trigger: '2',
    },
  ];
      return (
        <ThemeProvider theme={theme}>
          <ChatBot steps={steps} headerTitle='Ask Alice' />
        </ThemeProvider>
      );
  }

  export default ChatBotComponent;