import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Box,Grid} from '@mui/material/';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useMediaQuery } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// const matches = useMediaQuery('(min-width:600px)');

// return <span>{`(min-width:600px) matches: ${matches}`}</span>;
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useStyles } from './styled';

export interface Props {
  pane1: React.ReactNode;
  pane2: React.ReactNode;
}

const SplitPane2: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <Grid container xs={12} sx={{width: '100%'}}>

        <Grid item xs={6} style={{background: '#d5e6f7', padding: '10px', borderRadius: ' 10px 0 0 10px', backgroundImage: 'radial-gradient(#fff 1px, transparent 0.5px)',backgroundSize: '10px 10px'}}   >
        {props.pane1}

        </Grid>

        <Grid item xs={6} style={{background: '#fff', padding: '10px', borderRadius: ' 0 10px 10px 0'}}   >

        {props.pane2}
        </Grid>

    </Grid>
  );
};

export default SplitPane2;
