import React, { useState } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {
  Box

} from '@mui/material/';
import LargeCard from '../../components/LargeCard/LargeCard';
import GoBack from '../../components/GoBack/GoBack'
import { Link, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useStyles } from './styled';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SplitPane2 from '../../components/SplitPane2/SplitPane2';
import { PersonaContext } from '../../context/PersonaContext';
import { LanguageContext } from '../../context/LanguageContext';
import AvatarComponent from '../../components/AvatarComponent/AvatarComponent';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/'
import { emailExist, checkEmail } from '../../services/kioskApi'
import persona1Photo from '../../static/client1-borderexpo/image1-person.png';
import passport from '../../static/images/openPassport_no_photo.png';
import PersonIcon from '@mui/icons-material/Person';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


export interface Props { }

interface BioProps {
  client: string
}


//export const PassportComponent = ({}: any) => {
export const PassportComponent: React.FC<BioProps> = (props) => {
  const classes = useStyles();
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
    console.log(`language ${langContext.language}`);
    // retrieveData()
    // checkEmailExist()

  }, []);

  // Find Id image based on client Id
  const clientImageSearch = () => {
    let client_id = props.client;
    if (client_id == undefined) client_id = "1";
    const logo = require(`../../static/images/DIRS/${client_id}/portrait.jpg`);

    if (logo === 'undefined' || logo === null) {
      return <PersonIcon />
    }
    else {

      return <img src={String(logo)} style={{ borderRadius: '25px', width: '65%', textAlign: 'center', margin: 'auto' }} />


    }

  }



  return (
    <Grid
      container
      item
      xs={12}
      justifyContent='center'
      height='100%'
      width='100%'
    >
      <Typography
                variant='h5'
                component='h4'
                sx={{ fontWeight: 'bold', color: '#3c6cb2', marginBottom: 0, textAlign: 'center', fontFamily:'PoppinsNormal'}}
              >
                {t('idcompare3')}
              </Typography>
      <div className="spacer" />
      <Stack spacing={1} sx={{ margin: '25px auto' }}>
        <Typography style={{ color: '#3c6cb2', marginTop: '25px', fontWeight: 'bold' }} ></Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            margin: '0 auto',
            textAlign: 'center'
          }}
        >
          {clientImageSearch()}
        </Box>
        <div className="spacer" />
        <div className="spacer" />
      </Stack>
    </Grid>
  );
};



const PassportIDComparePage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();

  const [state, setState] = React.useState({
    dataValues: []


  })

  const formValues = {
    firstName: 'Clint',
    lastName: 'Barton',
    email: 'hawkeye@gmail.com',
  };

  const emailValue = {
    email: 'Gerald@gmail.com'
  };



  useEffect(() => {
    i18n.changeLanguage(langContext.language);
    console.log(`language ${langContext.language}`);
    // retrieveData()
    // checkEmailExist()

  }, []);

  const retrieveData = () => {
    emailExist(formValues)
      .then((response) => {
        setState({
          dataValues: response.data.body
        })
        console.log(response.data.body)
      })
      .catch((e) => {
        console.log(e);
      })
  }


  const passportID = () => {
    return <Grid container xs={12} >
      <Grid item xs={12} >
      <Typography
                variant='h5'
                component='h4'
                sx={{ fontWeight: 'bold', color: '#3c6cb2', marginBottom: 0, textAlign: 'center', fontFamily:'PoppinsNormal'}}
              >
                {t('idcompare4')}
              </Typography>
        <img src={passport} style={{ textAlign: 'center', margin: '65px', borderRadius: '20px' , width: '50%'}} />
      </Grid>
    </Grid>
  }

  const checkEmailExist = () => {
    checkEmail(emailValue)
      .then((response) => {
        setState({
          dataValues: response.data.body
        })

        console.log(response.data.body)
      })
      .catch((e) => {
        console.log(e);
      })
  }

  let language = langContext.language;
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // console.log(formValues);
    // alert('hello');
    console.log('Creating visitor');
    // context.createVisitor();
    navigate(`/passportOcr`);
  };

  return (
    <PersonaContext.Consumer>
      {(client) => (
          <Header>
          <Grid container style={{marginTop: 60}}>
             <Grid item xs={3}></Grid>
             <Grid container item xs={6} justifyContent='center'>
                <Typography
                  variant='h3'
                  align='center'
                  sx={{ fontWeight: 'bold', color: '#fff', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
                >
                  {t('idcompare1')}
                </Typography>
                <Typography
                  align='center'
                  sx={{ fontWeight: 'bold', color: '#fff', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
                >
                  {t('idcompare2')}
                </Typography>
                 <Stack spacing={4} justifyContent='center' alignItems='center'>
                   <Box>
                      <CheckCircleIcon sx={{color: '#BCD63E', fontSize: '96pt', marginTop: '30px'}}/>
                    </Box>
                    <SplitPane2
                      pane1={<PassportComponent client={client.client_id} />}
                      pane2={passportID()}
                    ></SplitPane2>
                    <Button
                      variant='contained'
                      onClick={(e) => handleSubmit(e)}
                      style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
                    >
                      {t('continue')}
                    </Button>

                  </Stack>
             </Grid>
             <Grid item xs={3}></Grid>
          </Grid>
        </Header>
      )}
    </PersonaContext.Consumer>
  );
};

export default PassportIDComparePage;
