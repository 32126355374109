import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import saiclogo from '../../static/images/SAIC_Logo_Package/Digital/4_White/SAIC-Logo-RGB-White-sm.png';
import Box from '@mui/material/Box';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import saicTri from '../../static/images/saicTri.png';

import { useStyles } from './styled';

interface Props { };

const WelcomePage: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      minHeight='100vh'
      className={classes.background}
    >
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography
            variant='h1'
            component='h2'
            align='left'
            marginLeft={25}
            sx={{color: '#ffffff', fontFamily: 'Poppins' }}
          >
            <svg xmlns='http://www.w3.org/2000/svg' height='164.12' width='529'>
              <path
                d='m61.278,41.392c2.388-11.316,0.816-15.126,17.298-15.09,21.846,0.042,69.408,0.054,69.408,0.054l6.708-26.316-84.21,0.012c-10.986,0.006-34.974-3.156-41.508,38.868-2.814,18.084-4.392,27.03-4.812,32.826-1.056,14.754,12.594,19.2,24.072,19.2,15.468,0,49.896,0.036,49.896,0.036,7.524,0,10.602-0.036,9.57,5.406-1.818,9.594-5.688,28.134-6.954,33.708-1.998,8.784-12.642,7.482-22.158,7.572h-72.304l-6.288,26.33h90.54c19.236,0,36.756-6.558,41.556-24.792,4.89-18.576,7.404-31.224,10.224-46.008,3.372-17.7-7.89-30.72-28.74-30.72h-43.992c-4.71,0-12.42,1.104-10.926-6.606,0.648-3.33,1.158-7.344,2.616-14.478'
                fill='#ffffff'
              />
              <path
                d='m255.83,164h33.624l-20.7-163.8h-37.686l-95.07,163.8h36.12s18.186-31.014,18.954-32.388h52.11l5.694-24.372h-44.49c0.006-0.006-0.054-0.048-0.054-0.048,3.054-5.046,37.266-60.912,38.004-62.238,0.174,1.164,13.494,119.05,13.494,119.05'
                fill='#ffffff'
              />
              <path
                d='m378.6,0.043644h-37.728l-36.318,163.96h38.394l35.66-163.96z'
                fill='#ffffff'
              />
              <path
                d='m434.35,40.818c1.11-5.166,2.502-14.478,15.15-14.478,6.552,0,72.84-0.006,72.84-0.006l6.648-26.268s-64.638,0.024-88.932,0.024c-33.84,0-36,32.556-38.16,43.356-2.166,10.8-17.34,78.24-17.964,81.282-3.942,19.188,6.24,39.39,35.58,39.39h81.06l6.15-26.454h-74.598c-14.334,0-14.7-8.496-12.612-19.17l7.368-38.718h0.03s4.146-23.652,7.44-38.958'
                fill='#ffffff'
              />
            </svg>
            <br/>
           Border Security Expo<br/> V1COM Demo<br/>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Grid
            container
            spacing={0}
            direction='column'
            alignItems='center'
            justifyContent='center'
            style={{ minHeight: '50vh' }}
          >
            <Grid item xs={12} rowSpacing={40}></Grid>
            <Grid item xs={12} columnSpacing={1}>
          
              <Button
              component={Link}
                to='/selectLang'
                variant='outlined'
                style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', fontFamily: 'PoppinsNormal'}}
              >
                Touch to Initialize &nbsp;<ArrowForwardIosIcon />
              </Button>
              
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WelcomePage;

// c
