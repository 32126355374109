import React, { useState } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Box, Grid, Stack, Skeleton, Paper} from '@mui/material/';
import LargeCard from '../../components/LargeCard/LargeCard';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useLongPress, LongPressDetectEvents } from 'use-long-press';

import { useStyles } from './styled';
import { makeStyles } from '@material-ui/styles';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import CropFreeIcon from '@mui/icons-material/CropFree';
import Typography from '@mui/material/Typography';
import { PersonaContext } from '../../context/PersonaContext';
import { LanguageContext } from '../../context/LanguageContext';
import { useContext, useEffect } from 'react';
import Hand from '../../static/images/HandScan.png'
import { useNavigate } from 'react-router-dom';
import Header from '../Header'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export interface Props {}

const BiometricsCollectionPage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [enabled, setEnabled] = React.useState(true);
  const [cssClass, setCssClass] = React.useState(classes.none);
  const [successButton, setSuccessButton] = React.useState(false);
  let maxTimeout = 3000;
  let currentTimeout = 0;
  let ad1 = 0;
  let ad2 = 1;
  let ad3 = 2;
  let ad4 = 3;

  let duration = 4;

  let navigate = useNavigate();
  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {

    i18n.changeLanguage(langContext.language);
    setTimeout(() => {
      console.log("timer started");
      navigate(`/biometricsSuccess`);

      }, 5000) 
  

  }, [])

  const getClass = () => {
    return cssClass;
  };

  const handleBiometrics = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    context.updateScenario(2);
    navigate(`/biometricsSuccess`);
  };

  return (
    <Header>
    <Grid container style={{marginTop: 60}}>
       <Grid item xs={3}></Grid>
       <Grid container item xs={6} justifyContent='center'>
          <Typography
            variant='h3'
            align='center'
            sx={{ fontWeight: 'bold', color: '#fff', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
          >
            {t('bio1')}
          </Typography>
           <Stack spacing={4} style={{marginTop: 60, width: '100%'}} justifyContent='center' alignItems='center'>
           <div  className="scannerHand" >
                <div  className="handPrint" >
                  
                    <Grid container xs={12} >
                   <div className="scannerLine" />
                    
                      <Grid item xs={12} style={{margin: '0 auto'}} >

                      <Typography
                          variant='h3'
                          component='h3'
                          style={{ fontSize: '20px', color: '#BCD63E',position:'absolute', margin: '615px 0 0 20px', textAlign: 'center' }}
                        >
                          {t('bio2')}
                        </Typography>

                            <div style={{background: '#b2d6e9',width: '100%',borderRadius: '20px', padding: '3px'}}>

                              <div className="circleFlasher1" />
                              <div className="circleFlasher2" />
                              <div className="circleFlasher3" />
                              <div className="circleFlasher4" />
                              <div className="circleFlasher5" />
                              <img src={Hand} style={{width: '550px', borderRadius: '20px'}} />
                            </div>
                          </Grid>
                        </Grid>
                    </div>
              </div>
            </Stack>
       </Grid>
       <Grid item xs={3}></Grid>
    </Grid>
  </Header>
  );
};

export default BiometricsCollectionPage;
