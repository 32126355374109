import React, {useEffect} from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Box,
       Grid,
       Typography,
       Card,
       Paper,
       Avatar,
       Skeleton
} from '@mui/material/';

import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SplitPane2 from '../../components/SplitPane2/SplitPane2';
// const matches = useMediaQuery('(min-width:600px)');

// return <span>{`(min-width:600px) matches: ${matches}`}</span>;
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useStyles } from './styled';
import LargeCard from '../../components/LargeCard/LargeCard';
import Header from '../Header/'
import CropFreeIcon from '@mui/icons-material/CropFree';
import LinearProgress from '@mui/material/LinearProgress';
import BadgeIcon from '@mui/icons-material/Badge';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { PersonaContext } from '../../context/PersonaContext';
import { LanguageContext } from '../../context/LanguageContext';

export interface Props {}

const PassportOCRScanPage: React.FC<Props> = (props) => {

  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
  }, []);

  return (
    <Header>
      <Grid container style={{marginTop: 100}}>
         <Grid item xs={3}></Grid>
         <Grid container item xs={6} justifyContent='center'>
            <Typography
              variant='h3'
              align='center'
              sx={{ fontWeight: 'bold', color: '#fff', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
            >
              {t('ocrscan1')}
            </Typography>
             <Stack spacing={6} style={{marginTop: 100}} justifyContent='center'>
                <Box>
                  <BadgeIcon sx={{fontSize: '96pt', color: '#fff'}}/>
                </Box>
                <Button
                  variant='contained'
                  component={Link}
                  to='/scanProgress'
                  style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
                >
                  {t('ocrscan2')}
                </Button>
              </Stack>
         </Grid>
         <Grid item xs={3}></Grid>
      </Grid>
    </Header>
  );
};

export default PassportOCRScanPage;

