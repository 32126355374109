import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import {Grid} from '@mui/material/';
import saicTri from '../../static/images/saicTri.png';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useStyles } from './styled';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';
import { useContext, useEffect, useState } from 'react';
import triangleBg from '../../static/images/borderchallengebk.png';

export interface Props { }

const SelectLanguagePage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();

  const context = useContext(LanguageContext);
  const [client, setClient] = useState(context);

  const changeLanguageHandler = (languageValue: string) => {
    // const languageValue = e.target.value;
    i18n.changeLanguage(languageValue);
    context.setLanguage(languageValue);
    navigate(`/visitorReg`, { state: { language: languageValue } });
  };

  return (
    <>
    <img src={triangleBg} style={{position: 'absolute', top: -300, left: -125}}/>
    <Grid container style={{marginTop: 100}}>
      <Grid item xs={4}></Grid>
      <Grid container item xs={4} justifyContent='center'>
         <Typography
              variant='h3'
              align='center'
              sx={{ fontWeight: 'bold', color: '#fff', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
            >
              SELECT LANGUAGE
            </Typography>
            {/* <LargeCard> */}
              <Stack spacing={4} style={{marginTop: 100}} justifyContent='center'>
              <Button
            variant='contained'
            style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
            className={classes.languageButton}
            onClick={() => {
              changeLanguageHandler('en');
            }}
          >
            English
          </Button>
          <Button
            variant='contained'
            style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
            className={classes.languageButton}
            onClick={() => {
              changeLanguageHandler('es');
            }}
          >
            Español
          </Button>
          <Button
            variant='contained'
            style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
            className={classes.languageButton}
            onClick={() => {
              changeLanguageHandler('fr');
            }}
          >
            Français
          </Button>
          <Button
            variant='contained'
            style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
            className={classes.languageButton}
            onClick={() => {
              changeLanguageHandler('zh');
            }}
          >
            普通话
          </Button>
          <Button
            variant='contained'
            style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
            className={classes.languageButton}
            onClick={() => {
              alert("Other languages are currently unavailable");
            }}
          >
            Other languages
          </Button>
          </Stack>
      </Grid>
      <Grid item xs={4}></Grid>
    </Grid>
    </>
  );
};

export default SelectLanguagePage;
