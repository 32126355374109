import React, { useState } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Box from '@mui/material/Box';
import LargeCard from '../../components/LargeCard/LargeCard';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useStyles } from './styled';
import Header from '../Header';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import { Typography } from '@mui/material/';
import EmailIcon from '@mui/icons-material/Email';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { PersonaContext } from '../../context/PersonaContext';
import { LanguageContext } from '../../context/LanguageContext';
import { useContext, useEffect } from 'react';

export interface Props { }

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#BCD63E',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#BCD63E',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#BCD63E',
      },
    },
  },
})(TextField);

const EmailLoginPage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    phone: '',
  };
  const [formValues, setFormValues] = useState(defaultValues);
  
  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  return (
    <Header>
      <Grid container style={{marginTop: 100}}>
         <Grid item xs={4}></Grid>
         <Grid container item xs={4} justifyContent='center'>
            <Typography
              variant='h3'
              align='center'
              sx={{ fontWeight: 'bold', color: '#fff', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
            >
              {t('emailverification')}
            </Typography>
            {/* <LargeCard> */}
             <Stack spacing={6} style={{marginTop: 100}} justifyContent='center'>
                <CssTextField
                  label={t('email')}
                  id='fname-input'
                  name='email'
                  variant='outlined'
                  defaultValue={formValues.firstName}
                  onChange={handleInputChange}
                  sx={{borderColor: "#94dbfc !important"}}
                  InputLabelProps={{style: {color: '#fff'}}}
                />
                <Button
                  variant='contained'
                  component={Link}
                  to='/passportOcrScan'
                  style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
                >
                  {t('email')}
                </Button>
              </Stack>
            {/* </LargeCard> */}
          {/* </Box> */}
         </Grid>
         <Grid item xs={4}></Grid>
      </Grid>
    </Header>
  );
};

export default EmailLoginPage;
