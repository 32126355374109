import React, { FC, useState, useContext } from 'react';
import { User, createNewVisitorRecord, getClientData, emailExist } from '../services/kioskApi';

// export const initial = {
//   client_id: 1,
//   first_name: 'joes',
//   last_name: 'biden',
//   updateLastName: (lastName: string) => {}, //update just the last name
//   fetchClient: () => {}, //alternatively, update the entire state,
//   createVisitor: () => {},
// };

// export const ContextExample = React.createContext(initial);
interface IPersonaContext {
  client_id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  height: string;
  height_units: string;
  country: string;
  gender: string;
  scenario: number;
  fetchClient: any;
  updateLastName: any;
  createVisitor: any;
  dob: string;
  visitor_id: any;
  expirationDate: string;
  issueDate: string;
  passport_number: string;
  updateVisitorId: any;
  updateScenario: any;
  getVisitor: any;
}

export const PersonaContext = React.createContext<IPersonaContext>(
  {} as IPersonaContext
);

interface Props {}

interface FormProps {
  firstName: string;
  lastName: string;
  email: string;
}

const ApiProvider: FC<Props> = (props: any) => {
  const context = useContext(PersonaContext);
  const [client, setClient] = useState(context);

  const updateLastName = (lastName: string) => {
    setClient((prevState) => ({ ...prevState, last_name: lastName }));
  };

  const updateScenario = (scenario_num: number) => {
    setClient((prevState) => ({ ...prevState, scenario: scenario_num }));
  };

  const updateVisitorId = (visitorId: any) => {
    setClient((prevState) => ({ ...prevState, visitor_id: visitorId }));
  };

  const fetchSomething = async () => {
    /*
    const { data } = await axios.get(
      `https://jsonplaceholder.typicode.com/users`
    );
    
    setClient(data);
    */
  };

  const createVisitor = async (id : any, visitorId: any) => {
    let result: any;
    result = {client_id: id }
    console.log(id)
    console.log(`visitor id ${visitorId}`);
    getClientData(result)
      .then((response: any) => {
        console.log(response.data);
        result = response.data[0];
        result.visitor_id = visitorId;
        console.log("-----");
        console.log(result);
        setClient(result);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getVisitor = async (id : any, visitorId: any) => {
    let result: any;
    result = {client_id: id }
    console.log(id)
    console.log(`visitor id ${visitorId}`);
    getClientData(result)
      .then((response: any) => {
        console.log(response.data);
        result = response.data[0];
        result.visitor_id = visitorId;
        console.log("-----");
        console.log(result);
        setClient(result);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  return (
    <PersonaContext.Provider
      value={{
        ...client,
        fetchClient: fetchSomething,
        updateLastName: updateLastName,
        createVisitor: createVisitor,
        getVisitor: getVisitor,
        updateScenario: updateScenario,
      }}
    >
      {props.children}
    </PersonaContext.Provider>
  );
};

export { ApiProvider };
