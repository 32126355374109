import React, { useState } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {
  Box

} from '@mui/material/';
import LargeCard from '../../components/LargeCard/LargeCard';
import GoBack from '../../components/GoBack/GoBack'
import { Link, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useStyles } from './styled';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SplitPane2 from '../../components/SplitPane2/SplitPane2';
import { PersonaContext } from '../../context/PersonaContext';
import { LanguageContext } from '../../context/LanguageContext';
import AvatarComponent from '../../components/AvatarComponent/AvatarComponent';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import Header from '../Header'
import { emailExist, checkEmail } from '../../services/kioskApi'
import persona1Photo from '../../static/client1-borderexpo/image1-person.png';
import passport from '../../static/images/openPassport_no_photo.png';
import PersonIcon from '@mui/icons-material/Person';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


export interface Props { }


const BiometricsProcessedPage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();

  const [state, setState] = React.useState({
    dataValues: []


  })

  let language = langContext.language;
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // console.log(formValues);
    // alert('hello');
    console.log('Creating visitor');
    // context.createVisitor();
    navigate(`/passportOcr`);
  };

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
  }, []);

  return (
    <PersonaContext.Consumer>
      {(client) => (
          <Header>
          <Grid container style={{marginTop: 60}}>
             <Grid item xs={3}></Grid>
             <Grid container item xs={6} justifyContent='center'>
                <Typography
                  variant='h3'
                  align='center'
                  sx={{ fontWeight: 'bold', color: '#fff', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
                >
                  {t('bio3')}
                </Typography>
                <Typography
                  align='center'
                  variant='h4'
                  sx={{ fontWeight: 'bold', color: '#BCD63E', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
                >
                  {t('bio4')}
                </Typography>
                 <Stack spacing={4} style={{marginTop: 50}} justifyContent='center' alignItems='center'>
                   <Box>
                      <CheckCircleIcon sx={{color: '#BCD63E', fontSize: '96pt'}}/>
                    </Box>
                    <Button
                      variant='contained'
                      component={Link}
                      to='/thankYou'
                      style={{marginTop: 50, borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
                    >
                      {t('continue')}
                    </Button>

                  </Stack>
             </Grid>
             <Grid item xs={3}></Grid>
          </Grid>
        </Header>
      )}
    </PersonaContext.Consumer>
  );
};

export default BiometricsProcessedPage;
