import React, { useState, ChangeEvent } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Box,Grid} from '@mui/material/';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import saicTri from '../../static/images/saicTri.png';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { User, createNewVisitor,getClientData } from '../../services/kioskApi';


import { useStyles } from './styled';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { PersonaContext } from '../../context/PersonaContext';
import { LanguageContext } from '../../context/LanguageContext';
import { withStyles } from '@mui/styles';
import '../../i18n';
import triangleBg from '../../static/images/borderchallengebk.png';

// import { useContextExample } from 'react';

export interface Props { }

interface NavigationParams {
  text: string;
}

interface CustomizedState {
  language: string;

}

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#BCD63E',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#BCD63E',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#BCD63E',
      },
    },
  },
})(TextField);
  
  

// type Navigation = NavigationScreenProp<NavigationState, NavigationParams>;

const VisitorRegistrationPage: React.FC<Props> = (props) => {
  const classes = useStyles();
  let navigate = useNavigate();

  // const value = useContext(ContextExample);
  // console.log(value);

  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  // const state = location.state as CustomizedState; // Type Casting, then you can get the params passed via router
  // const { language } = state;
  console.log(langContext.language);

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
  };

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
  }, []);

  let userValues: User;
  const [formValues, setFormValues] = useState<User>(defaultValues);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSkip = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleSubmit(event);
  };

  // Make a Post Request
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log(formValues);

    // Create New Record
      createNewVisitor(formValues)
      .then((response) => {
  
        console.log( response.data.message.client_id);     
        context.createVisitor(response.data.message.client_id, response.data.message.visitor_id);

      })
      .catch((e) => {
        console.log(e);
      })
     
    console.log('Creating visitor');
    navigate(`/persona`);
  };

  const setState = (response: any) => { };

  return (
    <>
  <img src={triangleBg} style={{position: 'absolute', top: -300, left: -125}}/>
    <Grid container style={{marginTop: 100, zIndex: 1}}>
         <Grid item xs={4}></Grid>
              <Grid container item xs={4} justifyContent='center'>
         <Typography
              variant='h3'
              align='center'
              sx={{ fontWeight: 'bold', color: '#fff', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
            >
              {t('boothVisitor')}
            </Typography>
            {/* <LargeCard> */}
              <Stack spacing={4} style={{marginTop: 100}} justifyContent='center'>

          <CssTextField
            label={t('firstName')}
            id='fname-input'
            name='firstName'
            variant='outlined'
            defaultValue={formValues.firstName}
            onChange={handleInputChange}
            InputLabelProps={{style: {color: '#fff'}}}
          />
          <CssTextField
            label={t('lastName')}
            id='lname-input'
            name='lastName'
            variant='outlined'
            defaultValue={formValues.lastName}
            onChange={handleInputChange}
            InputLabelProps={{style: {color: '#fff'}}}
          />
          <CssTextField
            label={t('email')}
            id='email-input'
            name='email'
            variant='outlined'
            defaultValue={formValues.email}
            onChange={handleInputChange}
            InputLabelProps={{style: {color: '#fff'}}}
          />
          <Button
            variant='contained'
            fullWidth
            onClick={(e) => handleSubmit(e)}
            style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
          >
            {t('submit')}
          </Button>
          <Typography sx={{color: '#fff', fontFamily: 'PoppinsNormal'}}>- {t('or')} -</Typography>
          <Button
            variant='contained'
            fullWidth
            onClick={(e) => handleSkip(e)}
            style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
          >
             {t('skip')}
          </Button>
          {/* <Button variant='text'onClick={(e) => handleSkip(e)} sx={{color: '#fff'}}>
          {t('skip')}
        </Button> */}
        </Stack>
         </Grid>
         <Grid item xs={4}></Grid>
    </Grid>
    </>
  );
};

export default VisitorRegistrationPage;

//Use Longpress.js
