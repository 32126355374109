import React, { useState } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {
  Box,
  Grid,
  Button, 
  Typography,
  Stack
} from '@mui/material';
import LargeCard from '../../components/LargeCard/LargeCard';
import { Link } from 'react-router-dom';

import { useStyles } from './styled';
import Header from '../Header/'
import { PersonaContext } from '../../context/PersonaContext';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { LanguageContext } from '../../context/LanguageContext';

export interface Props {}

const ThankYouPage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const nextSteps = ["", 
"Biometrics Collection",
"USCIS Interview",
"Resubmit Application"]



const context = useContext(PersonaContext);
const langContext = useContext(LanguageContext);
const location = useLocation();
const { t, i18n } = useTranslation();


useEffect(() => {
  i18n.changeLanguage(langContext.language);
}, []);

const waitTimes = ["", 
t('wait1'),
t('wait2'),
t('wait3'),]

  return (
      <PersonaContext.Consumer>
      {(client) => (
        <Header>
        <Grid container style={{marginTop: 60}}>
           <Grid item xs={3}></Grid>
           <Grid container item xs={6} justifyItems='center' justifyContent='center'>
             <Box>
                <Typography
                  variant='h3'
                  align='center'
                  sx={{ fontWeight: 'bold', color: '#fff', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px', justifyContent: 'center', justifyItems: 'center'}}
                >
                  {t('success')}
                </Typography>
              </Box>
               <Stack spacing={4} style={{marginTop: 50}} justifyContent='center' alignItems='center'>
                  <Stack spacing={4} sx={{width: '100%'}} direction="row">
                    <Typography  variant='h6' style={{textAlign: 'left', width: '350px', padding: '10px', color: '#fff', marginTop: '10px', fontWeight:'bold', fontFamily: 'PoppinsNormal'}} >{t('casenum')}</Typography>
                    <Typography  variant='h6' style={{textAlign: 'left', width: '100%', padding: '10px', color: '#fff', marginTop: '10px', fontFamily: 'PoppinsNormal'}} >15432696</Typography>
                  </Stack>
                  <Stack spacing={4} sx={{width: '100%'}} direction="row">
                    <Typography  variant='h6' style={{textAlign: 'left', width: '350px', padding: '10px', color: '#fff', marginTop: '10px', fontWeight:'bold', fontFamily: 'PoppinsNormal'}} >{t('nextsteps')}</Typography>
                    <Typography  variant='h6' style={{textAlign: 'left', width: '100%', padding: '10px', color: '#fff', marginTop: '10px', fontFamily: 'PoppinsNormal'}} >{nextSteps[client.scenario]}</Typography>
                  </Stack>
                  <Stack spacing={4} sx={{width: '100%'}} direction="row">
                    <Typography variant='h6' style={{textAlign: 'left', width: '350px', padding: '10px', color: '#fff', marginTop: '10px', fontWeight:'bold', fontFamily: 'PoppinsNormal'}} >
                      {t('waittimes')}
                    </Typography>
                    <Typography variant='h6' style={{textAlign: 'left', width: '100%', padding: '10px', color: '#fff', marginTop: '10px', fontFamily: 'PoppinsNormal'}} >{waitTimes[client.scenario]}</Typography>
                  </Stack>
                  <Stack>

                  </Stack>
                  <Typography
                  variant='h4'
                  align='center'
                  sx={{ fontWeight: 'bold', color: '#BCD63E', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
                >
                  {t('success2')}
                </Typography>
                 <Typography variant="h6" style={{padding: '10px', color: '#fff', marginTop: '10px', fontFamily: 'PoppinsNormal'}} ><a style={{textDecoration: 'none', color: '#BCD63E'}} href="http://localhost/tx.html" target="_blank">{t('viewmap')}</a> {t('map2')}</Typography>
                  
                  <Button
                    variant='contained'
                    component={Link}
                    to='/qr'
                    style={{marginTop: 50, borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
                  >
                    {t('continue')}
                  </Button>

                </Stack>
           </Grid>
           <Grid item xs={3}></Grid>
        </Grid>
      </Header>
        )}
        </PersonaContext.Consumer>
  );
};

export default ThankYouPage;
