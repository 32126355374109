import React, { useState } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Box from '@mui/material/Box';
import LargeCard from '../../components/LargeCard/LargeCard';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useStyles } from './styled';
import {emailExist } from '../../services/kioskApi';
import { PersonaContext } from '../../context/PersonaContext';
import { LanguageContext } from '../../context/LanguageContext';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import {Typography} from '@mui/material/';

export interface Props {}

const ReturningVisitorPage: React.FC<Props> = (props) => {
  const classes = useStyles();

  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);

  let navigate = useNavigate();

  const defaultValues = {
    email: '',
    firstName: '',
    lastName: ''
  };
  const [formValues, setFormValues] = useState(defaultValues);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // Make a Post Request
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log(formValues);

    // Create New Record
      emailExist(formValues)
      .then((response) => {
        // console.log(response.data.body);
        console.log( response.data.body.client_id);
        context.getVisitor(response.data.body.client_id, response.data.body.visitor_id);
      })
      .catch((e) => {
        console.log(e);
      })
    console.log('Get visitor');
    navigate(`/persona`);
  };
  
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      minHeight='75vh'
    >
      <Typography
        variant='h1'
        align='center'
        sx={{ fontWeight: 'bold', color: '#BCD63E', marginBottom: 10 }}
      >
        Enter Email
      </Typography>
      <LargeCard>
        <Stack spacing={2}>
        <TextField
            label='Email'
            id='email-input'
            name='email'
            variant='filled'
            defaultValue={formValues.email}
            onChange={handleInputChange}
          />
          <Button
            variant='contained'
            fullWidth
            style={{height: '60px', padding: '10px'}}
            className={classes.emailSubmitButton}
            onClick={(e) => handleSubmit(e)}
          >
            Submit
          </Button>
        </Stack>
      </LargeCard>
    </Box>
  );
};

export default ReturningVisitorPage;
