import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Box from '@mui/material/Box';
import LargeCard from '../../components/LargeCard/LargeCard';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useMediaQuery } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SplitPane2 from '../../components/SplitPane2/SplitPane2';
// const matches = useMediaQuery('(min-width:600px)');

// return <span>{`(min-width:600px) matches: ${matches}`}</span>;
import {
  Stack,
  Divider,
  Grid,
  Button

} from '@mui/material/';

import { Link } from 'react-router-dom';
import { useStyles } from './styled';
import { PersonaContext } from '../../context/PersonaContext';
import { LanguageContext } from '../../context/LanguageContext';
import { useContext, useEffect } from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Header from '../Header';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export interface Props { }

const KioskAuthenticate: React.FC<Props> = (props) => {
  const classes = useStyles();
  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  console.log(context.first_name);
  console.log(`scenario----${context.scenario}`);

  const alert = () => {
    window.alert('Email is not configured yet.')
  }

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
  }, []);

  return (
    <Header>
      <Grid container xs={12} spacing={3} className="fadePanel" alignItems='center' sx={{height: '100vh'}} >
        <Grid item xs={6} alignItems='top'>

          <Typography
            variant='h5'
            component='h5'
            align='left'
            sx={{
              fontWeight: 800,
              color: '#7cc9ed',
              marginBottom: 2,
              width: '100%',
              paddingLeft: '70px',
              fontSize: '90px',
              alignItems: 'top',
              fontFamily: 'PoppinsBold'
            }}
          >
            {t('welcome')}!
          </Typography>
          <Typography
            variant='h5'
            component='h5'
            align='left'
            sx={{
              color: '#ffffff',
              marginBottom: 10,
              width: '100%',
              paddingLeft: '70px',
              fontSize: '90px',
              alignItems: 'center',
              fontFamily: 'PoppinsNormal'
            }}
          >
            {t('touchtobegin')}
          </Typography>
        </Grid>
        <Grid item xs={6}  >
          <Stack spacing={4} justifyContent='center'>
            <Link to={`/phone`} style={{ textDecoration: 'none' }}>
              <LargeCard height='200px'>
                <Typography
                  variant='h1'
                  component='h2'
                  align='center'
                  sx={{ fontWeight: 'bold', color: '#3c6cb2', textAlign: 'center', fontSize: '40px', marginTop: '50px', fontFamily: 'PoppinsNormal' }}
                > <PhoneIcon sx={{fontSize: '24pt'}}/>&nbsp;
                  {t('phone')}
                  <span className="pageGradient"></span>
                </Typography>
              </LargeCard>
            </Link>

              <Link to={`/emailLogin`} style={{ textDecoration: 'none' }}>
                <LargeCard height='200px'>
                  <Typography
                    variant='h1'
                    component='h2'
                    align='center'
                    sx={{ fontWeight: 'bold', color: '#3c6cb2', textAlign: 'center', fontSize: '40px', marginTop: '50px', fontFamily: 'PoppinsNormal'}}
                  ><EmailIcon sx={{fontSize: '24pt'}}/>&nbsp;
                    {t('email')}
                  </Typography>
                </LargeCard>
              </Link>
            </Stack>

        </Grid>
      </Grid>
    </Header>
  );
};

export default KioskAuthenticate;
