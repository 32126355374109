import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useMediaQuery } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SplitPane2 from '../../components/SplitPane2/SplitPane2';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
// const matches = useMediaQuery('(min-width:600px)');

// return <span>{`(min-width:600px) matches: ${matches}`}</span>;
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useStyles } from './styled';
import { useTranslation } from 'react-i18next';
import '../../i18n';
import { useContext, useEffect } from 'react';
import { PersonaContext } from '../../context/PersonaContext';
import { LanguageContext } from '../../context/LanguageContext';
import AvatarComponent from '../../components/AvatarComponent/AvatarComponent';

import saicTri from '../../static/images/saicTri.png';
import { AnyStyledComponent } from "styled-components";
import { StaticTimePicker } from "@mui/lab";
import PersonIcon from '@mui/icons-material/Person';


export interface Props {}

interface CustomizedState {
  persona: Persona;
  language: string;
}

export interface Persona {
  client_id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  name_suffix: string;
  email: string;
  gender: string;
  height: string;
  height_units: string;
  weight: string;
  weight_units: string;
  hair_color: string;
  eye_color: string;
  nationality: string;
  street_address: string;
  city: string;
  state: string;
  country: string;
  phone_number: string;
  primary_language: string;
  scenario: string;
}

// type AvatarProps = {
//   firstName: string;
//   lastName: string;

// };

export type PersonaProps = {
  clientId?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  nameSuffix?: string;
  email?: string;
  gender?: string;
  height?: string;
  heightUnits?: string;
  weight?: string;
  weightUnits?: string;
  hairColor?: string;
  eyeColor?: string;
  nationality?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  country?: string;
  phoneNumber?: string;
  primaryLanguage?: string;
  scenario?: string;
  persona?: Persona;
  language?: string;
};

export const PersonaComponent = ({
  firstName,
  lastName,
  gender,
  height,
  heightUnits,
  country,
  clientId
}: PersonaProps) => {
  const classes = useStyles();
  const location = useLocation();
  const { t, i18n } = useTranslation();



  return (
    <Stack spacing={1} style={{marginTop: 'px'}} >
      <div className="spacer" />
      <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#3563a7'}}><b>{t(`name`)}</b></Typography>
      <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#000'}}>{firstName} {lastName}</Typography>
      <div className="dividerShape" />
      <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#3563a7'}}><b>{t(`gender`)}</b></Typography>
      <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#000'}}>{gender}</Typography>
      <div className="dividerShape" />
      <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#3563a7'}} ><b>{t(`height`)}</b></Typography>
      <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#000'}}>
        {height} {heightUnits}
      </Typography>
      <div className="dividerShape" />
      <Typography sx={{fontFamily: 'PoppinsNormal', padding: '10px', fontSize: '16px',color: '#3563a7'}} ><b>{t(`countryCap`)}</b></Typography>
      <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#000'}}>{country}</Typography>
      <div className="dividerShape" />
      <div className="spacer" />
      <div style={{ alignItems: 'center' }}>
        <div className="spacer" />
        <div className="spacer" />
      </div>
    </Stack>
  );
};

const PersonaPage: React.FC<Props> = (props) => {

  const [state, setState] = React.useState({
    clientImageId: ''  
  })

  const classes = useStyles();
  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  console.log("context");
  console.log(context);
  useEffect(() => {
    i18n.changeLanguage(langContext.language);
    console.log(`language ${langContext.language}`);
  }, []);


  let navigate = useNavigate();

  // Make a Post Request
  const handleNaturalization = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    context.updateScenario(1);
    navigate(`/kioskAuth`);
  };

  const handleGreencard = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    context.updateScenario(2);
    navigate(`/kioskAuth`);
  };

  const handleRejected = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    context.updateScenario(3);
    navigate(`/kioskAuth`);
  };

  let language = langContext.language;

const clientImageSearch = () => {
  let client_id = context.client_id;
  if (client_id == undefined) client_id = "1";
  const logo = require(`../../static/images/DIRS/${client_id}/portrait.jpg`);

  return <img src={String(logo)} width="80%" style={{ borderRadius: '50%', marginTop: 40}} />
}

  return (
    <PersonaContext.Consumer>
      {(client) => (
        <Grid container xs={12} style={{marginTop: 100}}>
         <Grid item xs={6} sx={{padding: 4}}>
         <Typography
            variant='h1'
            component='h1'
            align='left'
            sx={{
              fontWeight: 'bold',
              color: '#ffffff',
              marginBottom: 10,
              fontSize: '40px',
              fontFamily: 'Poppins',
              letterSpacing: 3,
            }}
          >
            {t('randomAssign')}
            <span className={classes.txtClr}> {t('persona')}</span>
            &nbsp;{t('forthisdemo')} <br/><br/>{t('persona3')}
            <br/><br/>
            <div style={{fontFamily: 'PoppinsNormal', fontWeight: 'bold', textAlign: 'center'}}> {t('persona4')}</div>
            <Stack direction="row" display='flex'
              justifyContent='center'
              alignItems='center' style={{marginTop: 40}} spacing={2}>
              <Button
                    variant='outlined'
                    style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleNaturalization(e)}
                  >
                    {t('scenario1')}
                  </Button>
                  <Button
                    variant='outlined'
                    style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleGreencard(e)}
                  >
                    {t('scenario2')}
                  </Button>
                  <Button
                    variant='outlined'
                    style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
                    onClick={(e : React.MouseEvent<HTMLButtonElement>) => handleRejected(e)}
                  >
                    {t('scenario3')}
                  </Button>
              </Stack>
          </Typography>
           </Grid> 
         <Grid item xs={6} sx={{padding: 4}}>
          <SplitPane2
              // Find Id image based on client Id
              pane1={
                clientImageSearch()
              }  
              pane2={
                <PersonaComponent
                clientId={client.client_id}
                height={client.height}
                heightUnits={client.height_units}
                gender={client.gender == 'M' ? t('male') : t('female')}
                country={client.country}
                firstName={client.first_name}
                lastName={client.last_name}
                />
              }
              ></SplitPane2>
           </Grid> 
        </Grid>
      )}
    </PersonaContext.Consumer>

  );
};

export default PersonaPage;
